import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Space, Button, Layout, Menu, Avatar, Badge, notification, Flex, Card, Modal } from 'antd';
import { UserOutlined, MessageFilled, BellOutlined, FolderOpenFilled, HistoryOutlined } from '@ant-design/icons';

import ExchangeRateInfo from '@controls/exchange-rate-info/exchange-rate-info';

import { exception } from '@extensions/notification';
import { isNewYearAroundUs, getDateTimeLocal } from '@extensions/utils';

import { useAppSelector, useAppDispatch } from '@store/hooks';
import { userLoaded } from '@store/actions';
import { setCurrentMenuItem, cleanupStates, setRestartServiceRequired } from '@store/actions';
import { serverFetch } from '@src/core/server';
import '@src/core/index.css';

import { IPushNotification } from '@entities/push-notification';
import { IUserSession } from '@entities/user-session';

import { UserType } from '@enums/user-type';
import { NotificationType } from '@enums/notification-type';

import {
    LogisticsIcon,
    ImportTruckIcon,
    DeliveryTruckIcon,
    BoxesIcon,
    ContactsIcon,
    BillIcon,
    MainLogoIcon,
    AccountingIcon,
    MiniLogoIcon,
} from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const MainLayout = () => {
    const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate();

    const { Header, Content, Sider } = Layout;

    const location = useLocation();

    const d = useAppDispatch();
    const currentMenuItem = useAppSelector<string>((s) => s.currentMenuItem);
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [newNotifications, setNewNotifications] = useState<Array<IPushNotification>>([]);
    const [newDocumentsQty, setNewDocumentsQty] = useState<number | undefined>();
    const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

    const [siderCollapsed, setSiderCollapsed] = useState<boolean>(false);

    function getItem(label: any, key: string, urlTo?: string, icon?: any, children?: Array<any>) {
        let itemlabel = urlTo ? <Link to={urlTo}>{label}</Link> : label;

        return {
            key,
            icon,
            children,
            label: itemlabel,
        };
    }

    useEffect(() => {
        let items = location.pathname.split('/');
        let key = items[1];

        d(setCurrentMenuItem(key));

        if (userSession.userSettings?.showNotifications) {
            getNewNotifications();
        }

        if (userSession.userSettings?.showBills) {
            getNewDocuments();
        }

        const interval = setInterval(() => {
            if (userSession.userSettings?.showNotifications) {
                getNewNotifications();
            }

            if (userSession.userSettings?.showBills) {
                getNewDocuments();
            }
        }, 5000); //per 5 sec

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setNotificationsOpen(newNotifications.length > 0);
    }, [newNotifications]);

    const onLogout = () => {
        serverFetch(`auth/logout`, { method: 'POST' })
            .then(() => {
                d(cleanupStates());
            })
            .catch(() => {
                d(cleanupStates());
            });
    };

    const getNewNotifications = async () => {
        d(setRestartServiceRequired(false));

        await serverFetch('notifications/unreadV2', { method: 'GET' })
            .then((data: Array<IPushNotification>) => {
                setNewNotifications(data);
            })
            .catch((ex) => {
                setNewNotifications([]);
            });
    };

    const getNewDocuments = async () => {
        d(setRestartServiceRequired(false));

        await serverFetch('documents/new', { method: 'GET' })
            .then((data) => {
                setNewDocumentsQty(data);
            })
            .catch((ex) => {
                setNewDocumentsQty(undefined);
            });
    };

    const onSetReadAll = () => {
        serverFetch(`notifications/readall`, { method: 'POST' })
            .then(() => {
                setNotificationsOpen(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения статуса сообщения', ex, () => d(userLoaded(undefined)));
            });
    };

    const onMenuClick = (e: any) => {
        d(setCurrentMenuItem(e.key));
    };

    const items: Array<any> = [];
    if (userSession.type === UserType.MainClient || userSession.type === UserType.SubClient) {
        items.push(getItem('Заказы', 'orders', '/orders', <LogisticsIcon />));
    } else if (userSession.type === UserType.Supplier || userSession.type === UserType.Employee) {
        items.push(getItem('Импорт', 'import', '/import', <ImportTruckIcon />));
        items.push(getItem('Доставка', 'delivery', '/delivery', <DeliveryTruckIcon />));
        items.push(getItem('Остатки', 'remainings', '/remainings', <BoxesIcon />));
    }

    if (userSession.userSettings?.showBills) {
        items.push(getItem('Счета', 'billheaders', 'billheaders', <BillIcon />));

        if (userSession.type === UserType.MainClient || userSession.type === UserType.Supplier) {
            items.push(getItem('Балансы', 'balances', '/balances', <AccountingIcon style={{ fontSize: 22 }} />));
            items.push(getItem('История операций', 'transactions', '/transactions', <HistoryOutlined />));
        }

        items.push(
            getItem(
                <>
                    Документы
                    {newDocumentsQty ? <Badge count={newDocumentsQty} color='blue' overflowCount={10} offset={[10, -1]}></Badge> : ''}
                </>,
                'files',
                'files',
                <FolderOpenFilled />
            )
        );
    }

    if (userSession.userSettings?.showNotifications) {
        items.push(
            getItem(
                <>
                    Уведомления
                    {newNotifications && <Badge count={newNotifications.length} overflowCount={10} offset={[10, -1]}></Badge>}
                </>,
                'notifications',
                'notifications',
                <MessageFilled />
            )
        );
    }

    items.push(getItem('Профиль', 'useraccount', '/useraccount', <UserOutlined />));

    items.push(getItem('Контакты', 'contacts', 'contacts', <ContactsIcon />));

    return (
        <>
            <Layout style={{ height: '100vh', minHeight: '100vh' }}>
                <Sider width={250} collapsible={true} collapsed={siderCollapsed} onCollapse={(e) => setSiderCollapsed(e)}>
                    <div className='logo'>
                        {siderCollapsed ? (
                            <>
                                {isNewYearAroundUs() && <div className='santa-hat-mini-logo'></div>}
                                <MiniLogoIcon style={{ fontSize: 32, display: 'table-cell', verticalAlign: 'middle' }} />
                            </>
                        ) : (
                            <>
                                {isNewYearAroundUs() && <div className='santa-hat-main-logo'></div>}
                                <MainLogoIcon style={{ fontSize: 48, display: 'table-cell', verticalAlign: 'middle' }} />
                            </>
                        )}
                    </div>
                    {items && <Menu selectedKeys={[currentMenuItem]} mode='inline' items={items} onClick={onMenuClick} />}
                </Sider>
                <Layout>
                    <Header className='main-header'>
                        <Flex align='center' justify='flex-end' gap='small'>
                            {userSession.userSettings?.showNotifications && (
                                <Button
                                    shape='circle'
                                    size='large'
                                    type='link'
                                    style={{ marginRight: 15 }}
                                    icon={
                                        <>
                                            <BellOutlined style={{ fontSize: 22, marginBottom: -3 }} />
                                            <Badge
                                                count={newNotifications.length}
                                                overflowCount={10}
                                                size='small'
                                                style={{
                                                    marginLeft: -10,
                                                    marginBottom: 25,
                                                }}
                                            />
                                        </>
                                    }
                                    onClick={() => {
                                        d(setCurrentMenuItem('notifications'));
                                        navigate(`/notifications`);
                                    }}
                                />
                            )}
                            <ExchangeRateInfo
                                setInternalUsdExchangeRate={false}
                                api={api}
                                useCBExchangeRate={userSession.userSettings?.useCBExchangeRate}
                                useInternalExchangeRate={userSession.userSettings?.useInternalExchangeRate}
                            />

                            <Space className='user-account-info'>
                                <Avatar
                                    style={{ backgroundColor: '#fff' }}
                                    size='large'
                                    src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                                />
                                <div className='user'>{userSession.fullName}</div>
                            </Space>
                            <Button type='link' className='logout' onClick={() => onLogout()}>
                                Выход
                            </Button>
                        </Flex>
                    </Header>
                    <Content className='main-content'>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
            <Modal
                title={<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'start', fontSize: 18 }}>Новое уведомление</div>}
                width={800}
                open={notificationsOpen}
                okButtonProps={{
                    style: { display: 'none' },
                }}
                cancelButtonProps={{
                    style: { display: 'none' },
                }}
                closable={false}
                styles={{
                    header: {
                        borderBottom: `1px solid #cdcdcd`,
                        borderRadius: 0,
                        paddingBottom: 10,
                    },
                    body: { overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' },
                }}
            >
                <div style={{ marginRight: 10 }}>
                    {newNotifications.map((n) => {
                        return (
                            <Card
                                key={n.id}
                                title={
                                    <Flex align='start' justify='space-between'>
                                        <div style={{ whiteSpace: 'break-spaces' }}>{n.subject}</div>
                                        <div style={{ color: '#828282', fontWeight: 'normal', fontSize: 14 }}>
                                            {getDateTimeLocal(n.createdOn)}
                                        </div>
                                    </Flex>
                                }
                                style={{ marginTop: 20, borderColor: n.type == NotificationType.User ? '#108EE9' : '' }}
                                styles={{
                                    header: {
                                        border: 'none',
                                    },
                                    body: { paddingTop: 10, fontSize: 14 },
                                }}
                            >
                                {n.body}
                            </Card>
                        );
                    })}
                    <Flex justify='flex-end' style={{ marginTop: 20 }}>
                        <Button onClick={() => onSetReadAll()} size='large' type='default'>
                            Закрыть
                        </Button>
                    </Flex>
                </div>
            </Modal>
        </>
    );
};

export default MainLayout;
