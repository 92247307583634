import React, { useContext, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Input, Select, Table, Tag, DatePicker, Form, notification } from 'antd';

import type { GetRef, GetProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { delayAction, getDateTimeLocal, getDate } from '@extensions/utils';
import { exception } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { serverFetch } from '@src/core/server';

import { IRemainingFilter } from '@entities/remaining-filter';
import { IRemaining } from '@entities/remaining';
import { IRemainingBoxGroup } from '@entities/remaining-box-group';
import { ICity } from '@entities/city';
import { ITruck } from '@entities/truck';
import { IUserSession } from '@entities/user-session';
import { IDeliveryPriority } from '@entities/delivery-priority';
import { IUserDeliverySetting } from '@entities/user-delivery-setting';

import { DayOfWeek } from '@enums/day-of-week';

type FormInstance<T> = GetRef<typeof Form<T>>;
type DatePickerRef = GetRef<typeof DatePicker>;
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

dayjs.extend(utc);

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex?: keyof IRemainingBoxGroup;
    record: IRemainingBoxGroup;
    handleSave: (record: IRemainingBoxGroup) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const filterContext: string = 'Remainings';

const Remainings = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const initFilter: IRemainingFilter = { userId: userSession.userId, toShippingOnly: true };

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const filter = useAppSelector<IRemainingFilter>((s) => s.filters[filterContext]);

    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [headers, setHeaders] = useState<Array<IRemaining>>([]);
    const [priorities, setPriorities] = useState<Array<IDeliveryPriority>>([]);
    const [deliveryDays, setDeliveryDays] = useState<Array<DayOfWeek>>([]);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch(`remainings/cities/${userSession.userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`remainings/trucks/${userSession.userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/${userSession.userId}/deliverysetting`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек доставки', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);
                setTrucks(result[0][1]);

                const deliverySetting: IUserDeliverySetting = result[0][2];
                let selectedDays: Array<DayOfWeek> = [];

                if (deliverySetting) {
                    selectedDays = JSON.parse(deliverySetting.daysOfWeek);
                    setDeliveryDays(selectedDays);
                }

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setPriorities([]);
        setHeaders([]);

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('remainings/groups', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения остатков', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                const entities: Array<IRemaining> = result[0][0];

                entities.map((e) => {
                    e.remainingBoxGroups.map((g) => {
                        g.shippingPriorityOn = g.shippingPriorityOn ? dayjs.utc(g.shippingPriorityOn).local() : undefined;
                    });
                });
                setHeaders(entities);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onSave = () => {
        serverFetch(`consignments/priorityshipping`, { method: 'POST', bodyData: priorities })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения желаемых дат доставки', ex, () => d(userLoaded(undefined)));
            });
    };

    const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
        const [editing, setEditing] = useState(false);
        const DatePickerRef = useRef<DatePickerRef>(null);
        const form = useContext(EditableContext)!;

        const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
            return deliveryDays.every(function (d) {
                return (current && current.valueOf() <= Date.now()) || d != current.day();
            });
        };

        useEffect(() => {
            if (editing) {
                DatePickerRef.current!.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            if (!dataIndex) return;

            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        };

        const save = async () => {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        };

        let childNode = children;

        if (editable && dataIndex) {
            childNode = editing ? (
                <Form.Item style={{ margin: 0 }} name={dataIndex}>
                    <DatePicker ref={DatePickerRef} onChange={save} format='DD.MM.YYYY' disabledDate={disabledDate} />
                </Form.Item>
            ) : (
                <div className='editable-cell-value-wrap' onClick={toggleEdit}>
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Сохранить',
                        key: 'save',
                        disabled: loading,
                        icon: <SaveOutlined />,
                        onClick: () => {
                            onSave();
                        },
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                display={showFilter}
                items={[
                    <Select
                        key='truck'
                        placeholder='Машина'
                        value={filter?.truckIds}
                        allowClear={true}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        style={{ width: 130 }}
                        showSearch
                        onChange={(value) => d(setFilter({ ...filter, truckIds: value }, filterContext))}
                        optionFilterProp='children'
                        options={trucks.map((t) => {
                            return { value: t.id, label: t.number };
                        })}
                    ></Select>,
                    <Select
                        key='city'
                        placeholder='Город'
                        value={filter?.cityIds}
                        allowClear={true}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        style={{ width: 200 }}
                        showSearch
                        onChange={(value) => d(setFilter({ ...filter, cityIds: value }, filterContext))}
                        optionFilterProp='children'
                        options={cities.map((c) => {
                            return { value: c.id, label: c.name };
                        })}
                    ></Select>,
                    <Input
                        key='markingCode'
                        style={{ width: 130 }}
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const expandedRemainings = (record: IRemaining) => {
        const handleSave = (row: IRemainingBoxGroup) => {
            const tmpPriorities = [...priorities];
            const priority = tmpPriorities.find((p) => p.boxGroupId === row.boxGroupId);

            const shippingPriorityOn = dayjs(row.shippingPriorityOn).utc(true).set('hour', 0).set('minute', 0).set('second', 0);

            if (priority) {
                priority.shippingOn = shippingPriorityOn;

                const index = tmpPriorities.findIndex((i) => i.boxGroupId === priority?.boxGroupId);
                tmpPriorities.splice(index, 1, {
                    ...priority,
                });
            } else {
                tmpPriorities.push({ boxGroupId: row.boxGroupId, shippingOn: shippingPriorityOn });
            }

            setPriorities(tmpPriorities);

            const tmpHeaders = [...headers];
            const indexHeader = tmpHeaders.findIndex((item) => item.cityKey === record.cityKey);
            const itemHeader = tmpHeaders[indexHeader];

            const tmpRemainingBoxGroups = [...record.remainingBoxGroups];
            const index = tmpRemainingBoxGroups.findIndex((item) => row.boxGroupId === item.boxGroupId);
            const item = tmpRemainingBoxGroups[index];

            tmpRemainingBoxGroups.splice(index, 1, {
                ...item,
                ...row,
            });

            itemHeader.remainingBoxGroups = tmpRemainingBoxGroups;

            setHeaders(tmpHeaders);
        };

        const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex?: string })[] = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                align: 'center',
                width: 80,
            },
            {
                title: 'Желаемая отгрузка',
                dataIndex: 'shippingPriorityOn',
                width: 160,
                hidden: !deliveryDays || deliveryDays.length <= 0,
                align: 'center',
                editable: true,
                render: (_, record) => {
                    const entity = record as IRemainingBoxGroup;
                    return entity.shippingPriorityOn ? getDate(entity.shippingPriorityOn) : '- Выберите дату -';
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 200,
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 150,
            },
            {
                title: 'AWB',
                width: 200,
                align: 'center',
                render: (record) => {
                    return (
                        record.awbNumber && (
                            <Tag color='#efefef' style={{ marginLeft: 10, color: '#000000' }}>
                                {record.awbNumber}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Машина',
                width: 150,
                render: (record) => {
                    return record.truckNumber || '- догруз -';
                },
            },
            {
                title: 'Отгрузка',
                width: 120,
                render: (record) => {
                    return getDate(record.shippedOn);
                },
            },
            {
                title: 'Приемка',
                render: (record) => {
                    return getDateTimeLocal(record.acceptedOn);
                },
                align: 'center',
                width: 120,
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                dataIndex: 'qty',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (record) => {
                    return record.volumeWeight?.toFixed(2);
                },
            },
            {},
        ];

        const columns = defaultColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: IRemainingBoxGroup) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    style: {
                        background: '#f5f9fc',
                        fontWeight: 600,
                    },
                }),
            };
        });

        return (
            <Table
                rowKey='key'
                size='small'
                dataSource={record.remainingBoxGroups}
                columns={columns as ColumnTypes}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                rowClassName={() => 'editable-row'}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IRemaining> = [
            {
                title: 'Город',
                dataIndex: 'cityName',
                key: 'cityName',
                width: 1018,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record: IRemaining) => {
                    return (
                        <>
                            <span>{record.cityName}</span>

                            {record.warehouseCode && (
                                <Tag color='#3e4657' style={{ marginLeft: 10, color: '#ffffff' }}>
                                    {record.warehouseCode}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                dataIndex: 'totalQty',
                key: 'totalQty',
                width: 120,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#ffee96',
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#ffee96',
                        fontWeight: 700,
                    },
                }),
                render: (_: any, record: IRemaining) => {
                    return record.totalVolume.toFixed(2);
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{
                    columnWidth: 32,
                    expandedRowRender: expandedRemainings,
                    defaultExpandedRowKeys: headers && headers.map((h) => h.cityKey),
                }}
                dataSource={headers}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? '240px' : '175px'})`, x: 'max-content' }}
                summary={(pageData) => {
                    let qty = 0;
                    let volume = 0;
                    pageData.forEach(({ totalQty, totalVolume }) => {
                        qty += totalQty ?? 0;
                        volume += totalVolume ?? 0;
                    });
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={2}>
                                    Итого:
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align='center'>
                                    {qty}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align='center'>
                                    {volume.toFixed(2)}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    );
                }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {contextHolder}
        </>
    );
};

export default Remainings;
