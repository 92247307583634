import React, { useState, useEffect, useRef } from 'react';

import { useResizeObserver } from 'usehooks-ts';

import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Table, Modal, Tag, Select, DatePicker, notification, Flex, Form, Switch } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    CloseOutlined,
    ReloadOutlined,
    MinusOutlined,
    PlusOutlined,
    SwapOutlined,
    QuestionCircleOutlined,
    EditOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import Account from '@controls/account';
import TransferAccountsModal from '@controls/balance-details/transfer-accounts-modal';

import { exception, securityRestriction, warning } from '@extensions/notification';
import { delayAction, toFinanceString, getEnumList, getDateTimeLocal } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ITransaction } from '@entities/transaction';
import { ITransactionFilter } from '@entities/transaction-filter';
import { IUserSession } from '@entities/user-session';
import { ITransactionParams } from '@entities/transaction-params';
import { IUser } from '@entities/user';
import { IUserAccount } from '@entities/user-account';

import { Currency } from '@enums/currency';
import { TransactionType, enumLabel as TransactionTypeLabel } from '@enums/transaction-types';
import { IEnumItem } from '@enums/enum-item';
import { OperationType, enumLabel as OperationTypeLabel } from '@enums/operation-type';
import { Permission, hasPermission } from '@enums/permission';
import { TransactionStatus } from '@enums/transaction-status';
import { UserAccountType } from '@enums/user-account-type';

dayjs.extend(utc);

interface ITransactionsControl {
    userId: string;
    hideInitiator?: boolean;
    isViewMode: boolean;
    onRefresh?: () => void;

    useTagRequired: boolean;
    tag: string | undefined;
}

const filterContext: string = 'Transactions';

const Transactions = (props: ITransactionsControl) => {
    const { userId, useTagRequired, tag, isViewMode, hideInitiator, onRefresh } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const initFilter: ITransactionFilter = {
        userId: userId,
        useTagRequired: useTagRequired,
        statuses: [TransactionStatus.Pending, TransactionStatus.Authorised],
        types: [TransactionType.Debiting, TransactionType.Depositing, TransactionType.Transfer],
    };

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<ITransactionFilter>((s) => s.filters[filterContext]);

    const { RangePicker } = DatePicker;

    const [modal, modalContextHolder] = Modal.useModal();
    const [api, contextHolder] = notification.useNotification();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentTransaction, setCurrentTransaction] = useState<ITransaction>();
    const d = useAppDispatch();

    const [transactions, setTransactions] = useState<Array<ITransaction>>([]);
    const [topTransaction, setTopTransaction] = useState<ITransaction>();
    const [totalDepositing, setTotalDepositing] = useState<number>(0);
    const [totalDebiting, setTotalDebiting] = useState<number>(0);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>();
    const [userAccounts, setUserAccounts] = useState<Array<IUserAccount>>([]);

    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [openTransferAccountsModal, setOpenTransferAccountsModal] = useState<boolean>(false);

    const [transactionTypes] = useState<Array<IEnumItem>>(getEnumList(TransactionType, TransactionTypeLabel));
    const [operationTypes] = useState<Array<IEnumItem>>(getEnumList(OperationType, OperationTypeLabel));

    const [useTag, setUseTag] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setTransactions([]);
        setSelectedIds([]);
        setCurrentTransaction(undefined);
        setTopTransaction(undefined);

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('transactions', { method: 'GET', queryParams: filter })
                    .then((data: Array<ITransaction>) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения платежных операций', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch(`users/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch(`users/${userId}/accounts`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения счетов пользователя', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                const entries: Array<ITransaction> = result[0][0];
                setTransactions(entries);

                setTopTransaction(entries[0]);

                const depositings = entries.filter(
                    (d) =>
                        d.currency == Currency.Usd && (d.userAccountType == UserAccountType.Transport || d.type == TransactionType.Transfer)
                );

                let tmpTotalDepositing = 0;
                depositings.map((d) => {
                    tmpTotalDepositing += d.amount;
                });
                setTotalDepositing(tmpTotalDepositing);

                const debitings = entries.filter(
                    (d) =>
                        d.currency == Currency.Usd && d.type == TransactionType.Debiting && d.userAccountType == UserAccountType.Transport
                );

                let tmpTotalDebiting = 0;
                debitings.map((d) => {
                    tmpTotalDebiting += d.amount;
                });
                setTotalDebiting(tmpTotalDebiting);

                setUser(result[0][1]);
                setUserAccounts(result[0][2]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter || filter.userId !== userId) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onCancel = () => {
        if (!currentTransaction) return;

        serverFetch(`transactions/${currentTransaction.id}/cancel`, { method: 'POST' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);

                if (onRefresh) onRefresh();
            })
            .catch((ex) => {
                exception(api, 'Ошибка отмены  платежной операции', ex, () => d(userLoaded(undefined)));
            });
    };

    const onUpdateTransfer = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/updatetransfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenTransferAccountsModal(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения операции перевода между счетами', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Select
                            key='transactionType'
                            placeholder='Тип операции'
                            value={filter?.types}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 330 }}
                            onChange={(value: any) => d(setFilter({ ...filter, types: value }, filterContext))}
                            options={transactionTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,
                        <Select
                            key='operationType'
                            placeholder='Тип платежа'
                            value={filter?.operationTypes}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 160 }}
                            onChange={(value: any) => d(setFilter({ ...filter, operationType: value }, filterContext))}
                            options={operationTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,
                        <RangePicker
                            style={{ width: 230 }}
                            allowEmpty={[true, true]}
                            key='date'
                            format='DD.MM.YYYY'
                            placeholder={['Операция с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[
                                filter?.createdFrom ? dayjs(filter?.createdFrom) : null,
                                filter?.createdTo ? dayjs(filter?.createdTo) : null,
                            ]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                createdFrom: undefined,
                                                createdTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            createdFrom:
                                                value && value[0]
                                                    ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                    : undefined,
                                            createdTo:
                                                value && value[1]
                                                    ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                    : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                        <Form.Item key='tag' label={tag} style={{ margin: 0, display: useTagRequired ? 'block' : 'none' }}>
                            <Switch
                                checked={useTag}
                                onChange={(value: boolean) => {
                                    setUseTag(value);

                                    if (value) {
                                        d(setFilter({ ...filter, tag: tag }, filterContext));
                                    } else {
                                        d(setFilter({ ...filter, tag: undefined }, filterContext));
                                    }
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => {
                        d(setFilter(initFilter, filterContext));
                        setUseTag(false);
                    }}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled:
                            !currentTransaction || currentTransaction.type != TransactionType.Transfer || !currentTransaction.exchangeRate,
                        hidden: isViewMode,
                        icon: <EditOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransactions)) {
                                securityRestriction(api, [Permission.ManageTransactions]);
                                return;
                            }

                            modal.confirm({
                                title: `Изменить выбранную платежную операцию?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    setOpenTransferAccountsModal(true);
                                },
                            });
                        },
                    },
                    {
                        label: 'Отменить',
                        key: 'cancel',
                        disabled: !currentTransaction,
                        hidden: isViewMode,
                        icon: <CloseOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.CancelTransaction)) {
                                securityRestriction(api, [Permission.CancelTransaction]);
                                return;
                            }

                            if (topTransaction?.id != currentTransaction?.id && topTransaction?.parentId != currentTransaction?.id) {
                                warning(api, 'Платежные операции можно отменять только последовательно, начиная с последней');
                                return;
                            }

                            if (topTransaction?.id == currentTransaction?.id && currentTransaction?.parentId != undefined) {
                                const parent = transactions.find((t) => t.id == currentTransaction.parentId);
                                warning(
                                    api,
                                    `Для отмены выбранной платежной операции отмените основную платежную операцию оплаты счета #${parent?.billFullNumber}`
                                );
                                return;
                            }

                            modal.confirm({
                                title: `Отменить выбранную  платежную операцию?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onCancel();
                                },
                            });
                        },
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            const entity = transactions.find((o) => o.id == selectedRowKeys[0]);
            setCurrentTransaction(entity);
        } else {
            setCurrentTransaction(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<ITransaction> = [
            {
                title: '#',
                width: 80,
                align: 'center',
                dataIndex: 'number',
            },
            {
                title: 'Операция',
                width: 190,
                render: (_, record) => {
                    return Operation(
                        record.amount,
                        record.currency,
                        record.userAccountType,
                        record.type,
                        record.operationType,
                        record.debtName
                    );
                },
            },
            {
                title: 'ИНН',
                align: 'center',
                width: 120,
                render: (_, record) => {
                    return record.inn;
                },
            },
            {
                title: 'Комиссия %',
                align: 'center',
                width: 110,
                render: (_, record) => {
                    if (!record.commission) return undefined;

                    return record.commission && record.commission.toFixed(2);
                },
            },
            {
                title: 'Дата',
                align: 'center',
                width: 140,
                render: (_, record) => {
                    return getDateTimeLocal(record.createdOn);
                },
            },
            {
                title: 'Инициатор',
                dataIndex: 'createdByName',
                width: 250,
                hidden: hideInitiator,
            },
            {
                title: 'Детали',
                render: (_, record) => {
                    return Comment(record.type, record.billFullNumber, record.billId, record.comment, record.debtName);
                },
            },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={transactions}
                loading={tableLoading}
                pagination={false}
                rowSelection={
                    isViewMode
                        ? undefined
                        : {
                              selectedRowKeys: selectedIds,
                              onChange: onSelectChange,
                              columnWidth: 32,
                              type: 'radio',
                          }
                }
                onRow={(record: ITransaction) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                footer={() => (
                    <span
                        style={{
                            fontWeight: 700,
                            fontSize: 16,
                        }}
                    >
                        <span style={{ marginLeft: 5 }}>
                            Всего внесено
                            <Tag color='var(--main-green)' style={{ marginLeft: 5, fontSize: 18 }}>
                                +{toFinanceString(totalDepositing, 2)} $
                            </Tag>
                        </span>

                        <span style={{ marginLeft: 5 }}>
                            Всего потрачено
                            <Tag color='var(--main-red)' style={{ marginLeft: 5, fontSize: 18 }}>
                                -{toFinanceString(totalDebiting, 2)} $
                            </Tag>
                        </span>
                    </span>
                )}
                virtual={true}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 237px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {user && currentTransaction && openTransferAccountsModal && (
                <TransferAccountsModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    accounts={userAccounts}
                    onCancel={() => setOpenTransferAccountsModal(false)}
                    onSave={(value: ITransactionParams) => onUpdateTransfer(value)}
                    transaction={currentTransaction}
                    api={api}
                />
            )}

            {contextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Transactions;

export const BackgroundColor = (type?: TransactionType) => {
    switch (type) {
        case TransactionType.Debiting:
        case TransactionType.DebtWriteOff: {
            return '#fbe7eb';
        }
        case TransactionType.Refund: {
            return '#f2e5f2';
        }
        case TransactionType.Depositing: {
            return '#e8edeb';
        }
        case TransactionType.Transfer: {
            return '#e5e5ff';
        }
        default:
            return '';
    }
};

export const Color = (type?: TransactionType) => {
    switch (type) {
        case TransactionType.Debiting:
        case TransactionType.DebtWriteOff:
        case TransactionType.Refund: {
            return 'var(--main-red)';
        }
        case TransactionType.Depositing: {
            return 'var(--main-green)';
        }
        case TransactionType.Transfer: {
            return 'blue';
        }
        default:
            return '';
    }
};

export const Operation = (
    amount?: number,
    currency?: Currency,
    userAccountType?: UserAccountType,
    transactionType?: TransactionType,
    operationType?: OperationType,
    debtName?: string
) => {
    if (!userAccountType || !currency) return <></>;

    let operationIcon = <></>;
    let operation = <></>;

    const currencyIcon = <Account type={userAccountType} currency={currency} />;

    switch (operationType) {
        case OperationType.Cash: {
            operation = (
                <Tag color='green' style={{ color: '#000000' }}>
                    Наличный
                </Tag>
            );
            break;
        }
        case OperationType.NonCash: {
            operation = (
                <Tag color='var(--primary-color)' style={{ color: '#000000' }}>
                    Безналичный
                </Tag>
            );
            break;
        }
    }

    if (transactionType == TransactionType.DebtWriteOff) {
        operation = (
            <Tag color='var(--main-red)' style={{ color: '#FFFFFF' }}>
                {debtName}
            </Tag>
        );
    }

    const color = Color(transactionType);

    switch (transactionType) {
        case TransactionType.DebtWriteOff:
        case TransactionType.Debiting:
        case TransactionType.Refund: {
            operationIcon = <MinusOutlined style={{ color: color }} />;
            break;
        }
        case TransactionType.Depositing: {
            operationIcon = <PlusOutlined style={{ color: color }} />;
            break;
        }
        case TransactionType.Transfer: {
            operationIcon = <SwapOutlined style={{ color: color }} />;
        }
    }

    return (
        <Flex gap='middle' align='center'>
            <div>{currencyIcon}</div>
            <div>
                {operationIcon}
                <span style={{ color: color, fontWeight: 600, marginLeft: 5 }}>{toFinanceString(amount, 2)}</span>
                <div>{operation}</div>
            </div>
        </Flex>
    );
};

export const Comment = (
    type?: TransactionType,
    billFullNumber?: string,
    billId?: string,
    comment?: string,
    debtName?: string,
    tableRef?: any
) => {
    return (
        <>
            {type == TransactionType.Depositing && !comment ? (
                <div>Внесение</div>
            ) : type == TransactionType.Debiting ? (
                <div>
                    <span>Списание по счету </span>
                    <a
                        style={{ fontWeight: 600 }}
                        onClick={() => {
                            if (tableRef) tableRef.current?.scrollTo({ key: billId });
                        }}
                    >
                        #{billFullNumber}
                    </a>
                </div>
            ) : type == TransactionType.DebtWriteOff ? (
                <div>
                    Списание задолженности <span style={{ fontWeight: 600 }}>"{debtName}" </span>
                    {billFullNumber ? (
                        <span>
                            <span>по счету </span>
                            <a
                                style={{ fontWeight: 600 }}
                                onClick={() => {
                                    if (tableRef) tableRef.current?.scrollTo({ key: billId });
                                }}
                            >
                                #{billFullNumber}
                            </a>
                        </span>
                    ) : undefined}
                </div>
            ) : (
                <></>
            )}

            {comment && <div>{comment}</div>}
        </>
    );
};
