import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Modal, Table, Tag, Form, Row, DatePicker, notification } from 'antd';
import type { GetRef, GetProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Key } from 'antd/es/table/interface';

import { LoadingOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { getDate } from '@extensions/utils';
import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { ITruck } from '@entities/truck';
import { IConsignment } from '@entities/consignment';
import { IUserSession } from '@entities/user-session';
import { IDeliveryPriority } from '@entities/delivery-priority';
import { IUserDeliverySetting } from '@entities/user-delivery-setting';
import { IBoxGroup } from '@entities/box-group';

import { DayOfWeek } from '@enums/day-of-week';

type FormInstance<T> = GetRef<typeof Form<T>>;
type DatePickerRef = GetRef<typeof DatePicker>;
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

dayjs.extend(utc);

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex?: keyof IConsignment;
    record: IConsignment;
    handleSave: (record: IConsignment) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const Consignments = () => {
    const d = useAppDispatch();
    const navigate = useNavigate();

    const { truckId } = useParams();

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalCntextHolder] = Modal.useModal();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [truck, setTruck] = useState<ITruck>();
    const [loading, setLoading] = useState<boolean>(false);

    const [priorities, setPriorities] = useState<Array<IDeliveryPriority>>([]);
    const [deliveryDays, setDeliveryDays] = useState<Array<DayOfWeek>>([]);

    const [headers, setHeaders] = useState<Array<IConsignment>>([]);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setHeaders([]);

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch(`trucks/${truckId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/${userSession.userId}/deliverysetting`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек доставки', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`consignments/groups`, {
                    method: 'GET',
                    queryParams: { truckIds: [truckId], userId: userSession.userId },
                })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setTruck(result[0][0]);

                const deliverySetting: IUserDeliverySetting = result[0][1];
                let selectedDays: Array<DayOfWeek> = [];

                if (deliverySetting) {
                    selectedDays = JSON.parse(deliverySetting.daysOfWeek);
                    setDeliveryDays(selectedDays);
                }

                const entities: Array<IConsignment> = result[0][2];

                entities.map((e) => {
                    if (e.boxGroups) {
                        e.boxGroups.map((g) => {
                            g.shippingPriorityOn = g.shippingPriorityOn ? dayjs.utc(g.shippingPriorityOn).local() : undefined;
                        });
                    }
                });

                setHeaders(entities);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const onSave = () => {
        serverFetch(`consignments/priorityshipping`, { method: 'POST', bodyData: priorities })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения желаемых дат доставки', ex, () => d(userLoaded(undefined)));
            });
    };

    const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
        const [editing, setEditing] = useState(false);
        const DatePickerRef = useRef<DatePickerRef>(null);
        const form = useContext(EditableContext)!;

        const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
            return deliveryDays.every(function (d) {
                return (current && current.valueOf() <= Date.now()) || d != current.day();
            });
        };

        useEffect(() => {
            if (editing) {
                DatePickerRef.current!.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            if (!dataIndex) return;

            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        };

        const save = async () => {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        };

        let childNode = children;

        if (editable && dataIndex) {
            childNode = editing ? (
                <Form.Item style={{ margin: 0 }} name={dataIndex}>
                    <DatePicker ref={DatePickerRef} onChange={save} format='DD.MM.YYYY' disabledDate={disabledDate} />
                </Form.Item>
            ) : (
                <div className='editable-cell-value-wrap' onClick={toggleEdit}>
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading || !truckId,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Сохранить',
                        key: 'save',
                        disabled: loading,
                        icon: <SaveOutlined />,
                        onClick: () => {
                            onSave();
                        },
                    },
                ]}
            />
        );
    };

    const expandedBoxGroups = (record: IConsignment) => {
        const handleSave = (row: IBoxGroup) => {
            const tmpPriorities = [...priorities];
            const priority = tmpPriorities.find((p) => p.boxGroupId === row.id);

            const shippingPriorityOn = dayjs(row.shippingPriorityOn).utc(true).set('hour', 0).set('minute', 0).set('second', 0);

            if (priority) {
                priority.shippingOn = shippingPriorityOn;

                const index = tmpPriorities.findIndex((i) => i.boxGroupId === priority?.boxGroupId);
                tmpPriorities.splice(index, 1, {
                    ...priority,
                });
            } else {
                if (row.id) tmpPriorities.push({ boxGroupId: row.id, shippingOn: shippingPriorityOn });
            }

            setPriorities(tmpPriorities);

            const tmpHeaders = [...headers];
            const indexHeader = tmpHeaders.findIndex((item) => item.cityKey === record.cityKey);
            const itemHeader = tmpHeaders[indexHeader];

            const tmpBoxGroups = [...(record.boxGroups || [])];
            const index = tmpBoxGroups.findIndex((item) => row.id === item.id);
            const item = tmpBoxGroups[index];

            tmpBoxGroups.splice(index, 1, {
                ...item,
                ...row,
            });

            itemHeader.boxGroups = tmpBoxGroups;

            setHeaders(tmpHeaders);
        };

        const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex?: string })[] = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                align: 'center',
                width: 80,
            },
            {
                title: 'Желаемая Отгрузка',
                dataIndex: 'shippingPriorityOn',
                width: 160,
                align: 'center',
                editable: true,
                render: (_, record) => {
                    const item = record as IBoxGroup;
                    return item.shippingPriorityOn ? getDate(item.shippingPriorityOn) : '- Выберите дату -';
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 200,
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 150,
            },
            {
                title: 'AWB',
                width: 130,
                align: 'center',
                render: (_, record) => {
                    const item = record as IBoxGroup;
                    return (
                        item.awbNumber && (
                            <Tag color='#efefef' style={{ marginLeft: 10, color: '#000000' }}>
                                {item.awbNumber}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'qty',
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {},
        ];

        const columns = defaultColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: IConsignment) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    style: {
                        background: '#f5f9fc',
                        fontWeight: 600,
                    },
                }),
            };
        });

        return (
            <Table
                rowKey='id'
                size='small'
                dataSource={record.boxGroups}
                columns={columns as ColumnTypes}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                rowClassName={() => 'editable-row'}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IConsignment> = [
            {
                title: 'Город',
                dataIndex: 'cityName',
                key: 'cityName',
                width: 718,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: 'Кол-во / шт',
                dataIndex: 'totalQty',
                key: 'totalQty',
                width: 120,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#ffee96',
                        fontWeight: 700,
                    },
                }),
            },
            {},
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{
                    columnWidth: 32,
                    expandedRowRender: expandedBoxGroups,
                    defaultExpandedRowKeys: headers ? (headers.map((h) => h.cityKey) as Key[]) : [],
                }}
                dataSource={headers}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px'})`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            <Row>
                <FormHeader title={`Информация о грузе (${truck?.number})`} />
            </Row>
            {renderToolbar()}
            {renderTable()}

            {contextHolder}
            {modalCntextHolder}
        </>
    );
};

export default Consignments;
