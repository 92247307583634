import React from 'react';
import { useAppSelector } from '@store/hooks';

import { IUserSession } from '@entities/user-session';

import BalanceDetailsControl from '@controls/balance-details/balance-details';

const BalanceDetails = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    return (
        <BalanceDetailsControl
            userId={userSession.userId}
            isViewMode={true}
            showExchangeRateInfo={false}
            useTagRequired={userSession.useTagRequired}
            tag={userSession.tag}
        />
    );
};

export default BalanceDetails;
