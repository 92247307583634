import React, { useState } from 'react';

import { useCopyToClipboard } from 'usehooks-ts';

import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Tooltip, Form, Tag, Flex, Divider, Button } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { CalendarOutlined, CopyOutlined } from '@ant-design/icons';

import { info, exception } from '@extensions/notification';
import { getDate } from '@extensions/utils';

import Comment from '@controls/comment/comment';

import { ICountry } from '@entities/country';
import { color as itemColor } from '@entities/item';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';
import { serverFetch } from '@src/core/server';

import { TruckIcon, HoldIcon, BoxesIcon, AirplaneIcon } from '@icons/index';

import './consignment-card.css';

dayjs.extend(utc);

export interface IBoxGroupCardItem {
    id?: string;
    itemName?: string;
    qty?: number;
    diffQty?: number;
    useAdjustmentQty?: boolean;
    adjustmentQty?: number;
    onHold?: boolean;
    onHoldReason?: string;
    loadingOn?: Dayjs;
    awbNumber?: string;
    cargoCarrierName?: string;
    truckNumber?: string;
    comment?: string;
    scannedBoxQty?: number;
}

interface IConsignmentCard {
    country: ICountry;
    items: Array<IBoxGroupCardItem>;
    useScanData?: boolean;
    allowComment?: boolean;
    api: NotificationInstance;
    onRefresh?: () => void;
}

const ConsignmentCard = (props: IConsignmentCard) => {
    const { country, items, useScanData, allowComment, api, onRefresh } = props;

    const d = useAppDispatch();

    const [, copy] = useCopyToClipboard();

    const handleCopy = (text: string) => () => {
        copy(text).then(() => {
            info(api, `"${text}" скопирован`);
        });
    };

    const onSaveComment = (boxGroupId: string, comment: string | undefined) => {
        serverFetch(`warehouse/boxgroup/${boxGroupId}/comment`, { method: 'POST', bodyData: comment })
            .then(() => {
                if (onRefresh) onRefresh();
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения комментария', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderCountryCard = () => {
        let index = 1;

        return items.map((item) => {
            ++index;

            return (
                <Form colon={false} key={index} labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}>
                    <Form.Item
                        label={
                            <Tooltip title='Количество'>
                                <BoxesIcon />
                            </Tooltip>
                        }
                        className='country-form-item'
                        style={{ fontWeight: 600 }}
                    >
                        <Flex align='center' justify='space-between' style={{ marginBottom: 2 }}>
                            <Flex align='center'>
                                <Tag color={itemColor(item.itemName)} style={{ marginBottom: 2, fontSize: 15, paddingBottom: 2 }}>
                                    {item.itemName}
                                </Tag>
                                <div style={{ fontSize: 18 }}>
                                    {useScanData && (
                                        <>
                                            <span style={{ color: item.scannedBoxQty === item.qty ? '#228B22' : 'red' }}>
                                                {item.scannedBoxQty}
                                            </span>
                                            <span> / </span>
                                        </>
                                    )}

                                    <span style={{ color: !item.qty || item.qty <= 0 ? 'red' : '' }}>{item.qty}</span>

                                    {item.useAdjustmentQty ? (
                                        <span style={{ marginLeft: 2, marginRight: 2 }}>({item.adjustmentQty})</span>
                                    ) : item.diffQty ? (
                                        <span style={{ color: item.diffQty > 0 ? 'green' : 'red', marginLeft: 2, marginRight: 2 }}>
                                            (<span>{item.diffQty > 0 && '+'}</span>
                                            <span>{item.diffQty}</span>)
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    {item.onHold && (
                                        <Tooltip placement='top' title={item.onHoldReason} color='darkorchid'>
                                            <HoldIcon style={{ color: 'darkorchid', fontSize: 18, marginLeft: 5 }} />
                                        </Tooltip>
                                    )}
                                </div>
                            </Flex>

                            {(allowComment || item.comment) && (
                                <Comment
                                    comment={item.comment}
                                    editMode={allowComment}
                                    onSave={(e) => {
                                        if (item.id) onSaveComment(item.id, e);
                                    }}
                                />
                            )}
                        </Flex>
                    </Form.Item>
                    {item.loadingOn && (
                        <Form.Item
                            label={
                                <Tooltip title='Отгрузка'>
                                    <CalendarOutlined style={{ fontSize: 18 }} />
                                </Tooltip>
                            }
                            className='country-form-item'
                        >
                            <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>{getDate(item.loadingOn)}</span>
                        </Form.Item>
                    )}
                    {item.awbNumber && (
                        <Form.Item label='AWB' className='country-form-item'>
                            <Flex className='copy-container' align='center' style={{ width: 'fit-content' }}>
                                <span style={{ fontSize: 16, marginRight: 2 }}>{item.awbNumber}</span>
                                <Tooltip title='копировать'>
                                    <Button
                                        className='hide-copy'
                                        size='small'
                                        type='link'
                                        icon={<CopyOutlined />}
                                        onClick={handleCopy(item.awbNumber)}
                                    />
                                </Tooltip>
                            </Flex>
                        </Form.Item>
                    )}
                    {item.cargoCarrierName && (
                        <Form.Item label={<AirplaneIcon style={{ fontSize: 14, marginLeft: 5 }} />} className='country-form-item'>
                            <Tag color='var(--cargo-color)'>{item.cargoCarrierName}</Tag>
                        </Form.Item>
                    )}

                    {item.truckNumber && (
                        <Form.Item
                            label={
                                <Tooltip title='Номер машины'>
                                    <TruckIcon />
                                </Tooltip>
                            }
                            className='country-form-item'
                        >
                            <Flex className='copy-container' align='center' style={{ width: 'fit-content' }}>
                                <span>{item.truckNumber}</span>
                                <Tooltip title='копировать'>
                                    <Button
                                        className='hide-copy'
                                        size='small'
                                        type='link'
                                        icon={<CopyOutlined />}
                                        onClick={handleCopy(item.truckNumber)}
                                    />
                                </Tooltip>
                            </Flex>
                        </Form.Item>
                    )}

                    {index > 1 && index <= items.length && (
                        <Form.Item wrapperCol={{ xs: 24 }} style={{ marginTop: -4 }} className='country-form-item'>
                            <Divider style={{ margin: 0 }} />
                        </Form.Item>
                    )}
                </Form>
            );
        });
    };

    return (
        <>
            <Flex
                vertical={true}
                style={{
                    border: `1px solid ${country.color || 'var(--main-green)'}`,
                    borderRadius: 6,
                    width: '100%',
                    height: 'fit-content',
                }}
            >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 600,
                        background: country.color || 'var(--main-green)',
                        color: '#ffffff',
                        textAlign: 'center',
                    }}
                >
                    {country.name}
                </div>
                <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>{renderCountryCard()}</div>
            </Flex>
        </>
    );
};

export default ConsignmentCard;
