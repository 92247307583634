import React from 'react';
import Transactions from '@controls/transactions/transactions';

import { useAppSelector } from '@store/hooks';

import { IUserSession } from '@entities/user-session';

const TransactionsHistory = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    return (
        <Transactions
            userId={userSession.userId}
            hideInitiator={true}
            isViewMode={true}
            useTagRequired={userSession.useTagRequired}
            tag={userSession.tag}
        />
    );
};

export default TransactionsHistory;
